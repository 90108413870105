<template>
  <div class="progress-circle">
    <svg :width="size" :height="size" viewBox="0 0 100 100" class="circle">
      <circle
        class="circle-background"
        cx="50"
        cy="50"
        r="45"
        fill="none"
        stroke-width="6"
      />
      <circle
        class="circle-progress"
        cx="50"
        cy="50"
        r="45"
        fill="none"
        :stroke="progressColor"
        stroke-width="6"
        stroke-linecap="round"
        :stroke-dasharray="circumference"
        :stroke-dashoffset="dashOffset"
      />
    </svg>
    <div class="percentage" :style="{'color':progressColor}">{{ Math.round(percent) }}%</div>
  </div>
</template>

<script>
export default {
  name: "ProgressCircle",
  props: {
    percent: {
      type: Number,
      required: true,
      validator: (value) => value >= 0 && value <= 100,
    },
    size: {
      type: Number,
      default: 80,
    },
    color: {
      type: String,
      default: "#1e30f3",
    },
    invertColors:{
      type: Boolean,
      default: false
    }
  },
  computed: {
    circumference() {
      return 2 * Math.PI * 45;
    },
    dashOffset() {
      return this.circumference * (1 - this.percent / 100);
    },
    progressColor() {
      const red = "#FF4149"
      const yellow= "#FCD34D"
      const green ="#96C800"
      const intenseGreen = "#638400"
      if(this.invertColors){
        if(this.percent < 10) return intenseGreen
        else if(this.percent < 15) return green
        else if(this.percent < 25) return yellow
        return red
      }
      else{
        if(this.percent < 50) return red
        else if(this.percent < 60) return yellow
        else if(this.percent < 75) return green
        return intenseGreen
      }
    }
  },
};
</script>

<style scoped>
.progress-circle {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.circle {
  transform: rotate(-90deg);
}

.circle-background {
  stroke: var(--circle-bg-color, #f8f8f8);
}

.circle-progress {
  transition: stroke-dashoffset 1s ease-in-out;
}

.percentage {
  position: absolute;
  font-family: Bariol;
  font-size: 20px;
  font-weight: 700;

}
</style>
