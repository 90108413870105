<template>
  <div class="chart-container">
    <canvas ref="impactoEmocionalChart" width="500" height="300"></canvas>
    <div class="overlay" :style="{ width: currentWidth + 'px', top:overlayTop + 'px',height: overlayHeight + 'px', left: overlayLeft + 'px' }"></div>
  </div>
</template>

<script>
import Chart from 'chart.js/auto';

export default {
  name: 'ChartNeuro',
  props: {
    labels: {
      type: Array,
      required: true,
    },
    data: {
      type: Array,
      required: true,
    },
    currentVideoTime: {
      type: Number,
      default: 0
    },
    totalDuration: {
      type: Number,
      default: 0
    }
  },
  data(){
    return {
      currentWidth: 0,
      overlayHeight:0,
      overlayLeft:0,
      overlayTop:0,
      overlayMaxWidth:0
    }
  },
  watch: {
    currentVideoTime(newTime) {
      if (this.totalDuration > 0 && newTime < this.totalDuration) {
        this.currentWidth = (newTime / this.totalDuration) * this.overlayMaxWidth;
      }
    },
  },
  mounted() {
    const ctx = this.$refs.impactoEmocionalChart.getContext('2d');

    const gradient = ctx.createLinearGradient(0, 0, 0, 400);
    gradient.addColorStop(0, '#FFFFFF');
    gradient.addColorStop(1, '#EAEAEA');

    const chartData = {
      labels: this.labels,
      datasets: [{
        label: this.$t('suite_watneuro_emotional_impact'),
        data: this.data,
        fill: true,
        backgroundColor: gradient,
        borderColor: '#6c30d0',
        pointBorderColor: '#3f065c',
        pointBackgroundColor: (context) => {
          const index = context.dataIndex;
          const value = context.dataset.data[index];
          const maxValue = Math.max(...context.dataset.data);
          const minValue = Math.min(...context.dataset.data);

          if (value === maxValue || value === minValue) {
            return '#000000';
          }
          return '#341566';
        },
        pointRadius: 5,
        pointHoverBackgroundColor: '#3f065c',
        pointHoverBorderColor: '#ffffff',
        pointHoverRadius: 7,
        borderWidth: 2,
      }],
    };

    const options = {
      responsive: true,
      maintainAspectRatio: false,
      layout: {
        padding: {
          top: 20,
          bottom: 30,
          left: 20,
          right: 20,
        },
      },
      plugins: {
        legend: {
          display: false,
        },
      },
      scales: {
        x: {
          grid: {
            display: true,
          },
          ticks: {
            display: true,
          },
        },
        y: {
          grid: {
            drawBorder: false,
          },
          ticks: {
            display: true,
          },
        },
      },
      elements: {
        line: {
          tension: 0.4,
        },
      },
    };

    const chart = new Chart(ctx, {
      type: 'line',
      data: chartData,
      options: options,
    });

    this.overlayHeight = chart.chartArea.height
    this.overlayTop = chart.chartArea.top
    this.overlayLeft = chart.chartArea.left
    this.overlayMaxWidth = chart.chartArea.width
  },
};
</script>

<style scoped>
.chart-container {
  position: relative;
  width: 500px;
  height: 300px;
}

canvas {
  max-width: 100%;
  height: auto;
}

.overlay {
  position: absolute;
  top: 20px;
  left: 54px;
  height: 222px;
  background-color: rgba(255, 0, 0, 0.08);
  pointer-events: none;
}
</style>
